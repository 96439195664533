<template>
  <div>
    <b-card
      no-body
      class="mb-0"
    >
      <div class="m-2">
        <!-- Table Top -->
        <b-row>

          <!-- Camera Base64-->
          <b-col
            cols="12"
            md="4"
            class="align-items-center justify-content-start mb-1 mb-md-0">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="success"
                class="btn-icon"
                @click="takePicturebase64"
              >
                <feather-icon icon="CameraIcon" />
                Take photo Base64
              </b-button>
              <b-card-text class="mt-3" style="font-weight: bold;">Photo base64</b-card-text>
              <b-card-text>Image: </b-card-text>
              <img v-bind:src="this.base64Image" width="100%" heigth="auto" />
          </b-col>

          <!-- Camera URL-->
          <b-col
            cols="12"
            md="4"
            class="align-items-center justify-content-start mb-1 mb-md-0">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="success"
                class="btn-icon"
                @click="takePictureURL"
              >
                <feather-icon icon="CameraIcon" />
                Take photo URL
              </b-button>
              <b-card-text class="mt-3" style="font-weight: bold;">Photo path</b-card-text>
              <b-card-text>Image: </b-card-text>
              <img v-bind:src="this.urlImage" width="100%" heigth="auto" />
          </b-col>

          <!-- GPS -->
          <b-col
            cols="12"
            md="4"
            class="align-items-center justify-content-start mb-1 mb-md-0">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="success"
                class="btn-icon"
                @click="getCurrentPosition"
              >
                <feather-icon icon="MapIcon" />
                Get GPS location
              </b-button>
              
              <b-card-text class="mt-3" style="font-weight: bold;">Your location is</b-card-text>
              <b-card-text>Latitude: {{ this.latitude }}</b-card-text>
              <b-card-text>Longitude: {{ this.longitude }}</b-card-text>

          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BCardText,
  BRow,
  BCol,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BButton,
  BTable,
  BLink,
  BDropdown,
  BDropdownItem,
  BPagination,
  BFormGroup,
  BFormCheckbox,
  BFormCheckboxGroup,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { Camera, CameraResultType } from '@capacitor/camera'
import { Geolocation } from '@capacitor/geolocation'


export default {
  components: {
    BCard,
    BCardText,
    BRow,
    BCol,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BButton,
    BTable,
    BLink,
    BDropdown,
    BDropdownItem,
    BPagination,
    BFormGroup,
    BFormCheckbox,
    BFormCheckboxGroup,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      loc: [],
      latitude: '',
      longitude: '',
      base64Image: '',
      urlImage: '',
      camera: 'rear',
      noRearCamera: false,
      noFrontCamera: false,
    }
  },
  computed: {

  },
  watch: {},
  created() {

  },
  mounted() {

  },
  methods: {

    takePicturebase64() {
      const image = Camera.getPhoto({
        quality: 90,
        allowEditing: false,
        width: 1024,
        preserveAspectRatio: true,
        resultType: CameraResultType.Base64
      })
      .then(res => {
        this.base64Image = 'data:image/jpeg;base64,'+res.base64String
        console.log(res.base64String)
      })
    },

    takePictureURL() {
      const image = Camera.getPhoto({
        quality: 90,
        allowEditing: false,
        width: 1024,
        preserveAspectRatio: true,
        resultType: CameraResultType.Uri
      })
      .then(res => {
        this.urlImage = res.webPath
        console.log(res.webPath)
      })
    },

    async getCurrentPosition() {
      const coordinates = await Geolocation.getCurrentPosition()
      this.latitude = coordinates.coords.latitude
      this.longitude = coordinates.coords.longitude
      console.log('Current', coordinates);
    },

    watchPosition() {
      const wait = Geolocation.watchPosition({}, (position, err) => {
      })
    }

  },
}
</script>
